import * as React from "react";
import Layout from "../../components/layout";
import HSCResults2024Content from "../../components/education/HSCResults2024Content";
import heroImage from "../../assets/education/DUX - Brandon Bai.png";
import BannerImage from "../../components/master/BannerImage";

const HSCResults2024Page = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="HSC Results 2022" />
        <HSCResults2024Content />
    </Layout>
  );
};

export default HSCResults2024Page;