import React from "react";
import "./enrolmentContent.css";
import PageTitle from "../master/PageTittle";
import ContentWrapper from "../master/ContentWrapper";
import SideBar from "../master/SideBar";
import { educational } from "../const";

const HSCResults2024 = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="2024 HSC Results" />

                <p>
                    We congratulate the class of 2024 for their commitment and hard work, we are proud of all our students.
                </p>

                <p>The outstanding highlights were:</p>

                <ul>
                    <li>Brandon Bai, College Dux – ATAR 99.65</li>

                    <li>Isabel Najjar – ATAR 95.90</li>
                    <li>Selina Nguyen – ATAR 94.15</li>
                    <li>Kenzi Truong – ATAR 93.55</li>
                    <li>Christian Atra - ATAR 90.50</li>
                </ul>

                <p>
                    6 students were awarded ATARs greater than 80.
                </p>

                <p>
                    We wish them all the very best in their future endeavors.
                </p>
            </div>
            <SideBar items={educational} title="Educational Excellence" />
        </ContentWrapper>
    </div>
);

export default HSCResults2024;
